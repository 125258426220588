.show {
  opacity: 1;
  transition: opacity 0.5s ease-in;
  transition-delay: 0.5s;
}

.hide {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.first-page-content {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
}

.sections-container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
}
