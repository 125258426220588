@font-face {
  font-family: "Oval";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/CampGranadaNF.otf") format("opentype");
}

html,
body {
  font-family: "Clear Sans", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI";
  margin: 0;
  padding: 0;
  background-image: url(../static/background-wide.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.aa {
  background: white no-repeat center center fixed;
  z-index: 9999;
}

.App {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
}

#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.background.show {
  opacity: 1;
  transition: all 0.5s ease-in;
}
.video-container {
  width: 100%;
  position: absolute;
  z-index: 1;
}
.hideVideo {
  opacity: 0;
  transition: all 0.5s ease-in;
}

.content {
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 200;
  z-index: 1;
  width: 50%;
  overflow: hidden;
}

.content.show {
  opacity: 1;
  transition: all 0.5s ease-in;
}

.content h2 {
  font-size: 4rem;
  color: #51ff00;
  font-family: "Oval", Courier, monospace;
  text-shadow: 0 0 20px #51ff00;
  font-weight: 100;
  margin-bottom: 1rem;
}

.content .content-item {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  width: 100%;
}

.content span {
  color: #51ff00;
}

@media screen and (max-width: 1350px) {
  body {
    height: 100vh;
  }

  .background.show {
    opacity: 1;
    transition: opacity 0.5s ease-in;
    height: auto;
  }
  .first-page-content {
    margin-bottom: 40px;
  }
  .button-group {
    flex-flow: column;
    align-items: center;
    width: 90%;
  }
  .content {
    width: 90%;
    font-size: 1rem;
  }
  .content > .content-item {
    font-size: 1rem;
    height: 500px;
    overflow: scroll;
    align-self: center;
    padding: 10px;
  }
}

@media screen and (max-width: 1240px) {
  body {
    background-image: url(../static/background.png);
    height: 100vh;
  }
}
@media screen and (max-width: 540px) {
  body {
    height: auto;
  }
  .content > h2 {
    margin-top: 50px;
  }
  .background.show {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 375px) {
  .content > h2 {
    margin-top: 150px;
  }
  .background.show {
    padding-bottom: 170px;
  }
}

@media screen and (max-width: 280px) and (min-height: 653px) {
  .content > h2 {
    margin-top: 250px;
  }
}

@media (max-aspect-ratio: 16/9) {
  #myVideo {
    width: auto;
    height: 100%;
  }
}
