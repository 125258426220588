$primary: #11998e;
$secondary: #38ef7d;
$white: #fff;
$gray: #9b9b9b;
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  height: 50vh;
  display: flex;
  flex-flow: column;
  width: 100%;
  gap: 10px;
  overflow: auto;
  padding-right: 17px;
  padding-left: 17px;
  margin-bottom: 50px;
  > .about-you-section {
    border: 1px solid $white;
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 30px;
    > div {
      position: relative;
      padding-top: 20px;
    }
  }
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $gray;
  outline: 0;
  font-size: 1.3rem;
  color: $white;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }

  &:not(:placeholder-shown) {
    border-color: $secondary;
  }
  &:not(:placeholder-shown) ~ .form__label {
    color: $secondary;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $gray;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $primary;
    font-weight: 700;
  }
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, $primary, $secondary);
  border-image-slice: 1;
}
/* reset input */
.form__field {
  &:required,
  &:invalid {
    box-shadow: none;
  }
}

.form__field:-webkit-autofill,
.form__field:-webkit-autofill:hover,
.form__field:-webkit-autofill:focus,
.form__field:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $white !important;
  caret-color: $white;
}

.companion-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
  > .companion-list {
    display: flex;
    border: 1px solid $white;
    border-radius: 5px;
    padding: 20px;
    flex-direction: row;
    gap: 10px;
    > div {
      position: relative;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 540px) {
  .companion-section {
    grid-template-columns: 1fr;
  }
}
