/* CSS */

.button-group {
  width: 100%;
  display: flex;
  gap: 10px;
}

.button-46 {
  align-items: center;
  background-color: rgba(240, 240, 240, 0.26);
  border: 1px solid #dfdfdf;
  border-radius: 16px;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 18px;
  justify-content: center;
  line-height: 28px;
  max-width: 100%;
  padding: 14px 22px;
  text-decoration: none;
  transition: all 0.2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
}

.button-46:active,
.button-46:hover {
  outline: 0;
}

.button-46:hover {
  background-color: #0a470a;
  border-color: rgba(0, 0, 0, 0.19);
  color: #82ff82;
}

@media (min-width: 768px) {
  .button-46 {
    font-size: 20px;
    min-width: 200px;
    padding: 14px 16px;
  }
}

@media screen and (max-width: 820px) {
  .button-group {
    flex-flow: column;
    align-items: center;
    width: 100%;
  }
}
